












































import { Component } from "vue-property-decorator";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import { demoSettingsService } from "@/services/internal-only/reset-demo-invoices.service";
import options from "@/shared/constants/toast-options";

@Component({
  components: {
    "primary-button": PrimaryButton
  }
})
export default class SalesDemoSettings extends UserRolesMixin {
  async resetInvoices() {
    await demoSettingsService.resetDemoInvoicesAsync();
    this.$toasted.show(
      "<p>Demo invoices have been reset.</p>",
      options.SUCCESS_OPTIONS
    );
  }
}
