import { app } from "@/main";
import { AxiosResponse } from "axios";

class DemoSettingsService {
  public resetDemoInvoicesAsync = async (): Promise<AxiosResponse<any>> => {
    const response = await app.$ravenapi.get("api/invoices/reset");
    return response;
  };
}

export const demoSettingsService = new DemoSettingsService();
